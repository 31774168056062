const loadAssets = (assets, callback = null) => {
  for (let x = 0; x < assets.length; x++) {
    const image = new Image()

    image.src = assets[x].src
    image.onload = () => {
      assets[x].el = image

      if (!assets[x].size) {
        assets[x].size = {
          w: image.width,
          h: image.height
        }
      }

      if (x + 1 === assets.length && callback) callback()
    }
  }
}

const nextSpriteFrame = (el, frame) => {
  if (frame % el.slowFactor !== 0 || el.paused) return

  let nextFrame
  if (el.currentSprite < el.spriteFrames) {
    nextFrame = el.currentSprite + 1
  } else if (el.loop) {
    nextFrame = 1
  } else {
    nextFrame = el.currentSprite
    el.paused = true
  }

  el.currentSprite = nextFrame
}

const animateSprites = (els, frame) => {
  for (let x = 0; x < els.length; x++) {
    nextSpriteFrame(els[x], frame)
  }
}

export { loadAssets, animateSprites }