// Core
import React from "react"

// Components
import { setCursorState } from "../cursor/set"

// Styles
import styles from "./closeBtn.module.scss"

// Assets
import Bg from "./assets/bg.inline.svg"
import Cross from "./assets/cross.png"
import Texture from "./assets/texture.png"

const CloseBtn = ({ event, buttonPos }) => {
  return (
    <button
      className={`button ${styles.closeBtn} ${
        buttonPos === `top` ? styles.closeBtnTop : ``
      }`}
      aria-label="Close Learn More Panel"
      onClick={event}
      onMouseEnter={() => setCursorState("pointer", true)}
      onMouseLeave={() => setCursorState("pointer", false)}
    >
      <Bg />
      <img
        className={styles.texture}
        src={Texture}
        width={47}
        height={47}
        alt=""
      />
      <img className={styles.icon} src={Cross} width={16} height={16} alt="" />
      <span className={styles.hoverCircle}></span>
    </button>
  )
}

export default CloseBtn
